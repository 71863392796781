import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import tuiImageEditor from 'tui-image-editor';
import { ToastUiImageEditorComponent } from 'ngx-tui-image-editor';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-image-annotate',
  templateUrl: './image-annotate.component.html',
  styleUrls: ['./image-annotate.component.scss']
})
export class ImageAnnotateComponent implements AfterViewInit, OnInit {
  title = 'annotator';
  editorOptions: tuiImageEditor.IOptions;
  @ViewChild(ToastUiImageEditorComponent, {static: false}) editorComponent: ToastUiImageEditorComponent;
  imgPreview = '';
  note = '';
  previewUrl;
  imageId: string;
  sending = false;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.imageId = params.get('id');

      this.editorOptions = {
        cssMaxHeight: 600,
        includeUI: {
          loadImage: {
            path: `https://api.wise-back.sancsoft.dev/media/${this.imageId}/file`,
            name: 'image'
          },
          menuBarPosition: 'bottom'
        },
      }
    });
  }

  ngAfterViewInit() {
    // this.editorComponent.editorInstance.loadImageFromURL('https://api.wise-back.sancsoft.dev/media/14fe7b88-9659-414d-997a-d78935d5f45b/file', 'image').then(t => console.log(t)).catch(console.log)
  }

  done() {
    this.sending = true;

    this.imgPreview = this.editorComponent.editorInstance.toDataURL();
    var blob = this.dataURItoBlob(this.imgPreview);
    var id = uuid();

    var formData = new FormData();
    formData.append('id', id);
    formData.append('type', '256');
    formData.append('source', '3');
    formData.append('note', this.note);
    formData.append('file', blob);
    this.httpClient.post<any>('https://api.wise-back.sancsoft.dev/media', formData).subscribe(
      (res) => {
        this.previewUrl = `https://api.wise-back.sancsoft.dev/media/${id}/file`;
        this.httpClient.put<any>(`https://api.wise-back.sancsoft.dev/media/${id}/notify`, {}).subscribe(
            (res) => {
              this.sending = false;
              this.router.navigate(['/annotate']);
            },
            (err) => {
              console.error(err);
              this.sending = false;
            }
        )
      },
      (err) => {
        console.error(err);
        this.sending = false;
      }
    );
  }

  dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
  }
}
