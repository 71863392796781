import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImageListComponent } from "./image-list/image-list.component";
import { ImageAnnotateComponent } from "./image-annotate/image-annotate.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DocumentListComponent } from "./document-list/document-list.component";


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'annotate',
    component: ImageListComponent
  },
  {
    path: 'annotate/:id',
    component: ImageAnnotateComponent
  },
  {
    path: 'documents',
    component: DocumentListComponent
  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
      {
        useHash: true
      })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
