import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';

import tuiImageEditor from 'tui-image-editor';
import { ToastUiImageEditorComponent } from 'ngx-tui-image-editor';
import { HttpClient } from '@angular/common/http';

import { v4 as uuid } from 'uuid';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
}
