import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media } from "../models/media";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {

  media$: Observable<Media[]>;
  showAnnotations = false;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.loadMedia();
  }

  loadMedia() {
    this.media$ = this.httpClient.get<Media[]>(`https://api.wise-back.sancsoft.dev/media?isAnnotation=${this.showAnnotations }`);
  }

  async deleteMedia(id: string) {
    await this.httpClient.delete(`https://api.wise-back.sancsoft.dev/media/${id}`).toPromise();
    this.loadMedia();
  }
}
