import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Document as Doc } from '../models/document';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  documents$: Observable<Doc[]>;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.loadDocuments();
  }
  
  loadDocuments() {
    this.documents$ = this.httpClient.get<Doc[]>('https://api.wise-back.sancsoft.dev/documents');
  }

  async deleteDocument(id: string) {
    await this.httpClient.delete(`https://api.wise-back.sancsoft.dev/documents/${id}`).toPromise();
    this.loadDocuments();
  }

  async generateDocument(id: string) {
    window.open(`https://api.wise-back.sancsoft.dev/documents/${id}/generate`);
  }
}
